.dropdown_content {
    top: 73px;
    right: 13px;
    z-index: 999;
    border: 1px solid #E8E8E8;
    position: absolute;
    width: 225px;
    /* Fill / White */

    background: #FFFFFF;
    border-radius: 4px;
}

.dropdown_content:after {
    content: " ";
    position: absolute;
    border-top: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;

    width: 12px;
    height: 12px;
    right: 13px;
    top: -6px;
    z-index: 998;

    /* Fill / White */

    background: #FFFFFF;
    transform: rotate(-45deg);
}

@media screen and (max-width: 600px) {
    .navbar-logout {
        padding: 0 0.5rem !important
    }
}