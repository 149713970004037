.progress-container {
    transform-origin: 32px 32px;
    -webkit-animation: rotate90 1s linear infinite normal;
    animation: rotate90 1s linear infinite normal;
}

.progress {
    -webkit-animation: progress 1s linear infinite normal;
    animation: progress 1s linear infinite normal;
    stroke: #1e76e3;
    stroke-dasharray: 47.1238898039px, 141.3716694116px;
    stroke-dashoffset: 47.1238898039px;
    stroke-width: 4;
    stroke-linecap: round;
}

.track {
    stroke: #000;
    stroke-opacity: 0.1;
    stroke-width: 4;
}

@-webkit-keyframes progress {
    10% {
        stroke-dasharray: 94.2477796077px, 94.2477796077px;
    }

    25% {
        stroke-dasharray: 146.0840583919px, 42.4115008235px;
    }

    32% {
        stroke-dasharray: 156.5298539651px, 31.9657052503px;
        stroke-dashoffset: 47.1238898039px;
    }

    54% {
        stroke-dasharray: 95.111717378px, 93.3838418374px;
        stroke-dashoffset: -47.1238898039px;
    }

    85% {
        stroke-dasharray: 47.1238898039px, 141.3716694116px;
        stroke-dashoffset: -94.2477796077px;
    }

    100% {
        stroke-dasharray: 47.1238898039px, 141.3716694116px;
        stroke-dashoffset: -94.2477796077px;
    }
}

@keyframes progress {
    10% {
        stroke-dasharray: 94.2477796077px, 94.2477796077px;
    }

    25% {
        stroke-dasharray: 146.0840583919px, 42.4115008235px;
    }

    32% {
        stroke-dasharray: 156.5298539651px, 31.9657052503px;
        stroke-dashoffset: 47.1238898039px;
    }

    54% {
        stroke-dasharray: 95.111717378px, 93.3838418374px;
        stroke-dashoffset: -47.1238898039px;
    }

    85% {
        stroke-dasharray: 47.1238898039px, 141.3716694116px;
        stroke-dashoffset: -94.2477796077px;
    }

    100% {
        stroke-dasharray: 47.1238898039px, 141.3716694116px;
        stroke-dashoffset: -94.2477796077px;
    }
}

@-webkit-keyframes rotate90 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

@keyframes rotate90 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

#spinner {
    position: absolute;
    right: 16px;
    top: 16px;
}

.react-pdf__Page__canvas {
    margin: auto;
}