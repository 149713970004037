.App {
    font-family: sans-serif;
    text-align: center;
}

.sigCanvas {
    width: 100%;
    max-width: 720px;
    min-width: 720px;
    max-height: 225px;
    min-height: 225px;
    height: 100%;
    /* Border / Light Grey */

    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 4px;
}

.sigImage {
    display: block;
    max-width: 50%;
    max-height: 50px;
    margin: 0 auto;
    border: 1px solid black;
    padding: 2rem;
}

.sign-here {
    position: absolute;
    display: flex;
    gap: 4px;
    top: 50%;
    left: 320px;
}

/* tablet */
@media (min-width: 768px) and (max-width: 1024px) {
    .sigCanvas {
        max-width: 760px;
        min-width: 760px;
    }

    .sign-here {
        top: 45%;
        left: 340px;
    }
}

/* tablet-min */
@media (min-width: 768px) and (max-width: 990px) {
    .sigCanvas {
        max-width: 520px;
        min-width: 520px;
    }

    .sign-here {
        top: 50%;
        left: 225px;
    }
}

/* mobile */
@media (max-width: 767px) {
    .sigCanvas {
        max-width: 340px;
        min-width: 340px;
    }

    .sign-here {
        top: 50%;
        left: 135px;
    }
}

/* Small-device */
@media (max-width: 375px) {
    .sigCanvas {
        max-width: 300px;
        min-width: 300px;
    }

    .sign-here {
        top: 50%;
        left: 110px;
    }
}

/* super Small-device */
@media (max-width: 320px) {
    .sigCanvas {
        max-width: 250px;
        min-width: 250px;
    }

    .sign-here {
        top: 50%;
        left: 85px;
    }
}