.mandate-documents {
    border-radius: 4px;
    height: 100%;
}

.mandate-documents::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.mandate-documents::-webkit-scrollbar-track {
    background: #F5F5F5;
    border-radius: 4px;

}

.mandate-documents::-webkit-scrollbar-thumb {
    background: #144FC5;
    opacity: 0.8;
    border-radius: 4px;

}

.mandate-documents::-webkit-scrollbar-thumb:hover {
    opacity: 1;
}